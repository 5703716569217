<template>
    <div>
        <portal to="layout-default-header">
            <header-index :title="$t('posts.create.title')">
                <template #create-new>
                    <btn-resource
                        class="btn-min-width"
                        variant="primary"
                        :resource="post"
                        @click="createPost()"
                    >
                        {{ $t('posts.actions.uploadMedia') }}
                    </btn-resource>
                </template>
            </header-index>
        </portal>

        <b-row align-h="center">
            <b-col cols="6">
                <wait-for-resource :resource="post">
                    <post-form
                        :post="post"
                        @submit.prevent="createPost()"
                    />
                </wait-for-resource>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {Post} from '@/models/Post';
import {detainFeedback} from '@/library/helpers';
import PostForm from '@/components/posts/PostForm';
import PostType from '@/library/enumerations/PostType';
import HeaderIndex from '@/components/layouts/HeaderIndex';
import TryCatchWithToast from '@/components/common/mixins/TryCatchWithToast';
import ConfirmLeaveUnsaved from '@/components/common/mixins/ConfirmLeaveUnsaved';

export default {
    name: 'PostCreate',
    components: {HeaderIndex, PostForm},
    mixins: [ConfirmLeaveUnsaved, TryCatchWithToast],
    data: function() {
        return {
            post: new Post({
                type: PostType.VIDEO,
            }),
        };
    },
    computed: {
        hasUnsavedChanges() {
            return !!this.post.changed();
        },
    },
    methods: {
        async createPost() {
            this.tryCatchWithToast(async() => {
                await this.post.save();

                // Save the post in Vuex, so we can continue uploading video in
                // the post's edit page.
                this.$store.commit('temp/setItem', {
                    key: 'post',
                    value: this.post,
                });

                // Navigate to the post's edit page.
                detainFeedback(() => {
                    this.$router.replace(this.post.getLocation());
                });
            }, {
                success: '',
            });
        },
    },
};
</script>
